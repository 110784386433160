<script>
import Swal from "sweetalert2";
import { userService } from '@/services';
import {cloneDeep, get} from 'lodash';
import * as moment from 'moment';
import {ADMIN_API_PATH, DATE_FORMAT} from '@/constants';
import { mapGetters } from 'vuex';
import TableCommon from "@/components/common/TableCommon.vue";
import i18n from "@/i18n";

export default {
    components: {
        TableCommon
    },
    setup() {

    },
    data() {
        return {
            rows: [],
            query: {
                search: '',
                page: 1,
                pageSize: 10,
                role: 'FACILITY_MANAGER'
            },
            loading: false,
            pagination: null,
            columns: [
              {
                sortable: false,
                label: '',
                type: 'stt'
              },
              {
                key: 'userIdStr',
                sortable: true,
                label: i18n.global.t('t-user-id')
              },
              {
                key: 'email',
                sortable: true,
                label: i18n.global.t('t-email-address'),
                dynamicText: (row) => `${get(row, 'email', '')}`,
              },
              {
                key: 'name',
                sortable: true,
                label: i18n.global.t('t-name'),
                maxWidth: 300,
              },
              {
                key: 'facility.name',
                dynamicText: (row) => `${get(row, 'facilitiesUser', []).join(' <br/> ')}`,
                specialTooltip: true,
                sortable: false,
                maxWidth: 300,
                label: i18n.global.t('t-name-of-facility')
              },
              {
                key: 'createdAt',
                type: "datetime",
                sortable: true,
                label: i18n.global.t('t-registration-date')
              },
              {
                key: 'updatedAt',
                sortable: true,
                type: "datetime",
                label: i18n.global.t('t-last-updated')
              },
              {
                key: 'status',
                type: 'status',
                sortable: false,
                label: i18n.global.t('t-enable-disable')
              },
              {
                key: 'action',
                options: ['edit', 'delete'],
                type: 'action',
                sortable: false,
                label: ''
              }
            ]
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format(DATE_FORMAT)
        },
        async getUsers(query) {
            try {
                this.loading = true;
                const response = await userService.getUsers(query);
                if (response.data) {
                    this.rows = cloneDeep(response.data);
                }
                if (response.pagination) {
                    this.pagination = cloneDeep(response.pagination);
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
            }
        },
        async refreshTable() {
          this.$refs.tableUserManagerFacility.refreshTable()
        },
        navigateUpdateFacility(userId) {
            if (this.currentUser.id === userId) {
                this.$router.push({ name: `profile` })
            } else {
                this.$router.push({ name: `admin-update-user`, params: { userId } })
            }
        },
        async handleDeleteFacility(value) {
            const {id, name} = value;

            const accept = await Swal.fire({
                title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${this.$t('deleted_message', { message: name })}</h2>`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('cancel'),
            });

            if (accept.value) {

                try {
                    const response = await userService.deleteUser(id);
                    if (response && response.deleted) {
                        await this.refreshTable();
                    }
                } catch (e) {
                    let message = e?.response?.data?.message || '';
                    await Swal.fire({
                        title: "",
                        text: message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }
        },
        paginate(pageNumber) {
            this.query.page = pageNumber;
        }
    },
    watch: {
        query: {
            handler(newValue) {
                this.getUsers(newValue)
            },
            deep: true
        }
    },
    mounted() {
        this.getUsers(this.query);
    },
    computed: {
      ADMIN_API_PATH() {
        return ADMIN_API_PATH
      },
        bindingClass() {
            return {
                'flex-grow-1': 'flex-grow-1',
                'ms-2': this.loading,
                'fw-bold': 'fw-bold',
                'fs-12': 'fs-12'
            }
        },
        ...mapGetters({
            currentUser: 'auth/currentUser'
        }),
    }
}
</script>

<template>
    <div class="table-responsive">
      <TableCommon
          ref="tableUserManagerFacility"
          :columns="columns"
          @onEdit="navigateUpdateFacility"
          @onDelete="handleDeleteFacility"
          :endpoint="ADMIN_API_PATH.ADMIN_USER"
          :query="{ role: 'FACILITY_MANAGER'}"
      />
    </div>
</template>
