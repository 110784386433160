<script>
import Layout from "../../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import FacilityManagerList from './facility-manager-list.vue';

export default {
    setup() {

    },
    components: {
        Layout,
        PageHeader,
        FacilityManagerList,
    },
    data() {
        return {
            title: this.$i18n.t('t-header-user-management'),
            items: [],
        }
    },
    mounted() {
    },
    methods: {
        onChangeTab(tabId) {
            this.$router.replace({
                name: 'user-management-by-admin', query: {
                    activeTab: tabId
                }
            })
        },
        toCreate() {
            this.$router.push({ name: `admin-create-user`, query: { role: 'FACILITY_MANAGER' } })
        }
    },
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card rounded-3">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h6 class="fs-16 mb-0">{{ $t("t-user-info") }}</h6>
                <b-button @click="toCreate" variant="dark" class="waves-effect waves-light w-sm pt-2 pb-2" data-text="追加">
                    <span class="fs-12">{{ $t('t-create') }}</span>
                </b-button>
            </div>
          <FacilityManagerList />
        </div>
    </Layout>
</template>
